import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"

import {
  Trans,
  useTranslation,
  I18nextContext,
  useI18next,
} from "gatsby-plugin-react-i18next"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import AdVertical from "../components/Ads/AdVertical"
import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import CircularProgress from "@material-ui/core/CircularProgress"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"

import axios from "axios"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const useStyles = makeStyles({
  card: {
    marginTop: 20,
    border: 0,
    marginBottom: 1,
    boxShadow: "0px 2px 14px rgba(32,33,36,0.12)",
    transition: "all .25s ease-in-out",
  },
  cardContent: {
    padding: "16px !important",
  },
  media: {
    height: 70,
    width: 70,
    borderRadius: 5,
    marginRight: 20,
  },

  title: {
    fontSize: 17,
    fontWeight: "500",
  },
  artist: {
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  details: {
    display: "flex",
    alignItems: "center",
  },
  index: {
    fontSize: 17,
    fontWeight: "700",
    marginRight: 20,
  },
})

export default function Charts() {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const dispatch = useContext(GlobalDispatchContext)
  const classes = useStyles()
  const context = useContext(I18nextContext)

  const [country, setCountry] = useState(
    context.language === "it" ? "it" : "us"
  )
  const [genre, setGenre] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    fetchCharts()
  }, [])

  useEffect(() => {
    fetchCharts()
  }, [genre, country])

  // fetch charts
  const fetchCharts = () => {
    setLoading(true)

    let apiUrl = `https://itunes.apple.com/${country}/rss/topsongs/limit=30/genre=${genre}/explicit=true/json`

    axios
      .get(apiUrl)
      .then(res => {
        const items = res.data.feed.entry
        setLoading(false)
        setData(items)
      })
      .catch(err => {
        setLoading(false)
        setError(true)
      })
  }

  // Build output
  const ResultItem = props => {
    const item = props.item
    const artist = item["im:artist"].label
    const title = item["im:name"].label
    const thumb = item["im:image"][2].label
    const fullTitle = item.title.label

    let langPrefix = context.language === "en" ? "" : "/" + context.language
    const shareURL = "/?q=" + fullTitle

    const handleClick = fullTitle => {
      dispatch({ type: "SEARCH", payload: { keyword: fullTitle } })
      navigate("/")
    }

    return (
      <Card className={`${classes.card}`}>
        <CardActionArea onClick={() => handleClick(fullTitle)}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs className={classes.details}>
                <div className={classes.index}>{props.index + 1}</div>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={thumb}
                    title={title}
                  />
                </div>

                <div>
                  <Typography
                    className={classes.title}
                    gutterBottom
                    component="h2"
                  >
                    {title}
                  </Typography>

                  <Typography
                    className={classes.artist}
                    color="textSecondary"
                    component="p"
                  >
                    {artist}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <ChevronRightIcon />

                {/*


                  <ButtonDownload
                    format="mp3"
                    onClick={() => setDownloadFormat("mp3")}
                    style={{ marginRight: 15 }}
                  />
*/}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  return (
    <Layout>
      <SEO title={t("charts")} description={t("charts-description")} />

      <Container maxWidth="md">
        <h1 style={{ marginTop: 40 }}>
          <Trans>charts</Trans>
        </h1>

        {error && (
          <div style={{ padding: 60 }}>Error, please try again later</div>
        )}

        <div style={{ padding: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-country-label">
                      <Trans>country</Trans>
                    </InputLabel>
                    <Select
                      labelId="select-country-label"
                      id="select-country"
                      value={country}
                      onChange={e => setCountry(e.target.value)}
                      label={<Trans>country</Trans>}
                    >
                      <MenuItem value="it">Italia</MenuItem>
                      <MenuItem value="us">USA</MenuItem>
                      <MenuItem value="gb">United Kingdom</MenuItem>
                      <MenuItem value="fr">France</MenuItem>
                      <MenuItem value="de">Germany</MenuItem>
                      <MenuItem value="es">Spain</MenuItem>
                      <MenuItem value="ro">Romania</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-country-label">
                      <Trans>genre</Trans>
                    </InputLabel>
                    <Select
                      labelId="select-country-label"
                      id="select-country"
                      value={genre}
                      onChange={e => setGenre(e.target.value)}
                      label={<Trans>genre</Trans>}
                    >
                      <MenuItem value="0">
                        <Trans>allMusic</Trans>
                      </MenuItem>
                      <MenuItem value="20">Alternative</MenuItem>
                      <MenuItem value="2">Blues</MenuItem>
                      <MenuItem value="21">Rock</MenuItem>
                      <MenuItem value="7">Electronic</MenuItem>
                      <MenuItem value="18">Rap</MenuItem>
                      <MenuItem value="14">Pop</MenuItem>
                      <MenuItem value="17">Dance</MenuItem>
                      <MenuItem value="24">Reggae</MenuItem>
                      <MenuItem value="12">Latino</MenuItem>
                      <MenuItem value="11">Jazz</MenuItem>
                      <MenuItem value="6">Country</MenuItem>
                      <MenuItem value="5">Classical</MenuItem>
                      <MenuItem value="15">R&B</MenuItem>
                      <MenuItem value="19">World</MenuItem>
                      <MenuItem value="4">Children</MenuItem>
                      <MenuItem value="16">Soundtrack</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {loading && (
                  <div style={{ textAlign: "center", marginTop: 25 }}>
                    <CircularProgress color="primary" />
                  </div>
                )}

                {!loading &&
                  data &&
                  data.length &&
                  data.map((item, index) => (
                    <div key={index}>
                      <ResultItem item={item} index={index} />
                    </div>
                  ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <AdVertical />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Layout>
  )
}
